import React, { useRef, useState } from "react";
import { Link } from 'gatsby';
import { graphql } from "gatsby";
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { css } from '@emotion/core';
import styled from "@emotion/styled";
import Layout from '../components/layout';
import Card from '../components/card';
import Button from '../components/button';
import ProductGallery from "../components/product-gallery";
import { addToCart } from '../common/cart';
import BRANDS from '../../data/brands.json';
import CATEGORIES from '../../data/categories.json';

const StrongTag = styled.strong`
  font-weight: 500;
`;

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: {eq: $slug} }) {
      frontmatter {
        discontinued
        slug
        title
        description
        categories
        brand
        price
        size
        images
        colors
      }
      body
    }
  }
`;

export default ({ data: { mdx: product } }) => {
  const qtyInputElm = useRef(null);
  const colorSelectElm = useRef(null);
  const [currentColor, setCurrentColor] = useState(product.frontmatter.colors ? product.frontmatter.colors[0] : '');

  function onColorChange(e) {
    const color = e.target.value;
    const imgName = product.frontmatter.images.find(imgName => imgName.startsWith(color + '.'));
    if (imgName) setCurrentColor(color);
  }

  function getProductForCart() {
    const productFrontmatter = product.frontmatter;
    const p = {
      title: productFrontmatter.title,
      slug: productFrontmatter.slug,
      price: productFrontmatter.price,
      qty: Number(qtyInputElm.current.value),
      img: productFrontmatter.images[0],
    };
    if (colorSelectElm.current) {
      p.color = colorSelectElm.current.value;
      const images = productFrontmatter.images;
      const imgName = images.find(imgName => imgName.startsWith(p.color + '.'));
      if (imgName) p.img = imgName;
    }
    return p;
  }

  function addProductInCart(e) {
    const button = e.target;
    button.disabled = true;
    button.textContent = 'Added. Check your cart';

    addToCart(getProductForCart());
    setTimeout(() => {
      button.disabled = false;
      button.textContent = 'Add to cart';
    }, 3000);
  }

  return (
    <Layout
      title={product.frontmatter.title + ' | PasteWax Pakistan'}
      description={product.frontmatter.description}
      slug={product.frontmatter.slug}
    >
      <div itemScope itemType="https://schema.org/Product" css={css`
        position: relative;
      `}>
        <ProductGallery discontinued={product.frontmatter.discontinued} slug={product.frontmatter.slug}
          images={product.frontmatter.images} color={currentColor} />

        <section id="product-options" css={css`
          box-sizing: border-box;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: calc(40% - 15px);

          @media (max-width: 960px) {
            position: static;
            width: 100%;
          }
        `}>
          <Card id="product-gallery" className="layout"
            css={css`
              position: sticky;
              top: 105px;

              @media (max-width: 960px) {
                border-top: none;
              }
            `}
          >
            <h1 className="card-heading" itemProp="name">{product.frontmatter.title}</h1>

            <p itemProp="brand" itemScope itemType="https://schema.org/Brand">
              <StrongTag>Brand:</StrongTag>
              &nbsp;
              <Link to={`/brand/${product.frontmatter.brand}`} itemProp="name">
                {BRANDS[product.frontmatter.brand].title}
              </Link>
            </p>

            <p>
              <StrongTag>Category:</StrongTag>
              &nbsp;
              {product.frontmatter.categories.map((key, i, arr) => {
                const category = CATEGORIES[key];
                const link = <Link itemProp="category" key={key} to={`/category/${key}`}>
                  {category}
                </Link>;

                if ((i + 1) === arr.length) {
                  return link;
                }

                return (
                  <span key={key}>
                    {link},&nbsp;
                  </span>
                );
              })}
            </p>

            <p>{product.frontmatter.size}</p>

            <p className={product.frontmatter.discontinued ? 'hide' : ''}
              itemProp="offers" itemScope itemType="https://schema.org/Offer">
              <StrongTag>Price:</StrongTag>&nbsp;
              <span itemProp="priceCurrency">PKR</span>&nbsp;
              <span itemProp="price">{product.frontmatter.price}</span>
              <link itemProp="availability" href="https://schema.org/InStock" />
            </p>

            <p className={product.frontmatter.colors ? '' : 'hide'}>
              <label htmlFor="color-select"
                css={css`
                  display: inline-block;
                  margin-right: 1rem;
                  font-weight: 500;
                `}
              >
                Color
              </label>
              {
                product.frontmatter.colors
                ?
                  <select id="color-select" ref={colorSelectElm} onChange={onColorChange}
                    css={css`
                      padding: 0.8rem;
                      width: 100%;
                    `}
                  >
                    {product.frontmatter.colors.map(color => <option key={color} value={color}>{color}</option>)}
                  </select>
                :
                  ''
              }
            </p>

            <p className={product.frontmatter.discontinued ? 'hide' : ''}>
              <label>
                <span css={css`
                  display: inline-block;
                  margin-right: 1rem;
                  font-weight: 500;
                `}>
                  Quantity
                </span>
                <input name="qty" type="number" defaultValue="1"
                  required min="1" ref={qtyInputElm}
                  css={css`
                    padding: 0.8rem;
                    width: 60px;
                  `}
                />
              </label>
            </p>

            <p className={product.frontmatter.discontinued ? 'hide' : ''}>
              <Button onClick={addProductInCart}
                css={css`
                  background: #fc0;
                  border: none;
                  cursor: pointer;
                  font-family: "Open Sans", sans-serif;
                  font-size: 0.9rem;
                  font-weight: 600;
                  color: #666;
                  padding: 15px;
                  width: 100%;
                `}
              >
                Add to cart
              </Button>
            </p>
          </Card>
        </section>

        <Card className="layout" css={css`
          box-sizing: border-box;
          margin-top: 2rem;
          width: calc(60% - 15px);
          @media (max-width: 960px) {
            width: 100%;
          }
        `}>
          <h2 className="card-heading">Description</h2>
          <div itemProp="description" css={css`
            iframe {
              width: 100%;
            }

            img {
              max-width: 830px;
              width: 100%;
            }
          `}><MDXRenderer>{product.body}</MDXRenderer></div>
        </Card>
      </div>
    </Layout>
  );
};
