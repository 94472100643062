import React, { useRef, useState, useEffect } from 'react';
import { css } from '@emotion/core';
import Card from '../components/card';
import { getLegacyImage, imgAltFromSlug } from '../common/utils';

const ProductThumbnail = (props) => {
  const imgSrc = `/static/images/${props.slug}/thumb-${props.name}`;

  return (
    <div className={props.name === props.current ? 'selected': ''}
      onClick={props.onClick}
      css={css`
        border: 2px solid transparent;
        cursor: pointer;
        display: inline-block;
        margin-right: .5rem;
        padding: 2px;
        width: 72px;
        height: 54px;

        &:last-child {
          margin-right: 0;
        }

        &.selected {
          border-color: #fc0;
          cursor: default;
        }
      `}>
      <img src={imgSrc} alt={imgAltFromSlug(props.slug)}
        css={css`
          display: block;
          height: 100%;
          width: 100%;
        `} />
    </div>
  );
};

let timer = 0;
const ProductGallery = (props) => {
  const images = props.images;

  const isProductDiscontinued = props.discontinued;
  const mapImgSrc = name => `/static/images/${props.slug}/${name}`;
  const [currentName, setCurrentName] = useState(images[0]);
  const [mainImgLoading, setMainImgLoading] = useState(true);
  const mainImgRef = useRef(null);

  useEffect(() => {
    const imgName = props.images.find(imgName => imgName.startsWith(props.color + '.'));
    if (!props.color || !imgName) return;
    const color = `${props.color}.webp`;
    if (color !== currentName) handler(color);
  }, [props.color]);

  function handler(imgName) {
    setMainImgLoading(true);
    setCurrentName(imgName);
  }

  function imgOnLoad(e) {
    if (mainImgLoading) setMainImgLoading(false);
  }

  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    timer = 0;
    const mainImgElm = mainImgRef.current;
    if (mainImgElm && mainImgElm.complete) {
      imgOnLoad(null);
    }
  }, 500);

  return (
    <Card id="product-gallery" className="layout"
      css={css`
        box-sizing: border-box;
        position: relative;
        width: calc(60% - 15px);

        @media (max-width: 960px) {
          width: 100%;
        }
      `}
    >
      <div className={mainImgLoading ? "" : "hide"}
        css={css`
          position: absolute;
          top: calc(50% - 20px - 40px);
          left: calc(50% - 20px);
          height: 40px;
          width: 40px;
      `}>
        <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          width="40px" height="40px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40" xmlSpace="preserve">
          <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
          <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
            C22.32,8.481,24.301,9.057,26.013,10.047z">
            <animateTransform attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 20 20"
              to="360 20 20"
              dur="0.5s"
              repeatCount="indefinite"/>
            </path>
        </svg>
      </div>

      {isProductDiscontinued ? <span css={css`
        position: absolute;
        background: #888;
        color: #fff;
        font-size: .8rem;
        font-weight: 400;
        padding: 0 6px;
        right: 20px;
      `}>
        Discontinued
      </span> : ''}

      <picture>
        <source srcSet={mapImgSrc(currentName)}></source>
        <img ref={mainImgRef} onLoad={imgOnLoad} src={getLegacyImage(mapImgSrc(currentName))}
          itemProp="image" className={mainImgLoading ? "hide" : ""} alt={imgAltFromSlug(props.slug)}
          css={css`
            display: block;
            width: 100%;
          `}
        />
      </picture>

      <img src="https://dummyimage.com/960x720/ffffff/8c8c8c" alt="Loading"
        className={mainImgLoading ? "" : "hide"}
        css={css`
          display: block;
          visibility: hidden;
          width: 100%;
        `}
      />

      <div css={css`
        margin-top: 1rem;
        white-space: nowrap;
        overflow-y: auto;
      `}>
        {images.map(imgName => {
          return (
            <ProductThumbnail key={imgName} name={imgName} slug={props.slug} current={currentName} onClick={(e) => handler(imgName)}></ProductThumbnail>
          );
        })}
      </div>
    </Card>
  );
};

export default ProductGallery;
